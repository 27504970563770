import React from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import { convertToEmbedLink } from 'utils/video/convertToEmbedLink';
import { InstagramEmbed } from './InstagramEmbed';

interface VideoOverlayProps {
  showVideoOverlay: boolean; // Controls whether the overlay is visible
  onClose: () => void; // Function to toggle the overlay
  videoUrl: string | null; // The YouTube video URL
  videoType: 'instagram' | 'youtube' | null;
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({
  showVideoOverlay,
  onClose,
  videoUrl,
  videoType,
}) => {
  if (!showVideoOverlay || !videoUrl || !videoType) return null; // Don't render anything if the overlay isn't active

  return (
    <div
      onClick={() => onClose()} // Close the overlay on click
      style={{
        zIndex: 1000,
      }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="w-full h-full md:w-4/5 relative flex items-center justify-center">
        <div className={`video-media-wrapper video-media-${videoType}`}>
          {onClose && (
            <img
              className="video-media-close"
              id="closeButton"
              src={`${ASSET_PREFIX}/images/close_button.svg`}
              style={{ cursor: 'pointer', width: '16px' }}
              onClick={onClose}
              alt="close button - cross"
            />
          )}
          {/* YouTube Video Embed */}
          {videoType === 'youtube' && (
            <div className="video-youtube aspect-video w-full rounded shadow-lg overflow-hidden">
              <iframe
                className="w-full h-full"
                src={convertToEmbedLink(videoUrl)}
                title="YouTube Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
          {/* Instagram Embed */}
          {videoType === 'instagram' && (
            <div className="video-instagram w-full h-full flex items-center justify-center rounded ">
              <InstagramEmbed videoLink={videoUrl} />
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .video-media-wrapper {
          position: relative;
          margin: 30px;
          padding: 25px 20px;
          background-color: #fff;
          border-radius: 20px;
          max-width: 95%;
        }

        .video-media-close {
          position: absolute;
          top: 12px;
          right: 14px;
        }

        .video-media-youtube {
          width: 100%;
        }

        .video-youtube {
          margin: 10px 0;
        }

        .video-instagram {
          padding-top: 10px;
          width: 100%;
          height: 100%;
        }

        @media (min-width: 475px) {
          .video-media-wrapper {
            padding: 30px;
          }
          .video-instagram {
            min-width: 406px;
            min-height: 680px;
          }
        }
      `}</style>
    </div>
  );
};

export default VideoOverlay;

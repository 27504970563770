import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { useEffect, useRef, useState } from 'react';

export function InstagramEmbed({ videoLink }: { videoLink: string }) {
  const containerRef = useRef(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (loaded) return;

    const triggerEmbed = () => {
      // @ts-ignore
      window.__igEmbedLoaded = () => {
        setLoaded(true);
      };
      // @ts-ignore
      if (window.instgrm) {
        // @ts-ignore
        window.instgrm.Embeds.process();
      }
    };

    const existingScript = document.getElementById('instagram-embed-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://www.instagram.com/embed.js');
      script.setAttribute('id', 'instagram-embed-script');
      script.async = true;
      script.defer = true;
      script.onload = () => triggerEmbed();
      document.head.appendChild(script);
    } else {
      triggerEmbed();
    }
  }, [videoLink]);

  return (
    <>
      <div
        className="instagram-embed overflow-y-auto w-full h-4/5 md:h-full"
        ref={containerRef}
      >
        {!loaded && (
          <div className="instagram-media-loading-spinner">
            <PlainLoadingSpinner />
          </div>
        )}
        <blockquote
          className="instagram-media"
          data-instgrm-permalink={videoLink}
          data-instgrm-version="14"
          style={{
            background: '#FFF',
            border: 0,
            margin: '30px',
            width: '100%',
          }}
        />
      </div>

      <style jsx>{`
        .instagram-embed::-webkit-scrollbar {
          display: none;
        }

        .instagram-media-loading-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .instagram-media {
          visibility: hidden;
        }

        .instagram-media-rendered {
          visibility: visible;
        }
      `}</style>
    </>
  );
}
